import React from 'react';

const CareersELearning = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.04 36.2556H5.72461C5.03133 36.2556 4.36664 35.9779 3.87642 35.4836C3.3862 34.9893 3.1106 34.3189 3.1106 33.6198V10.6358C3.1106 9.93674 3.3862 9.26619 3.87642 8.77188C4.36664 8.27758 5.03133 8 5.72461 8H41.04C41.7333 8 42.398 8.27758 42.8882 8.77188C43.3784 9.26619 43.654 9.93674 43.654 10.6358V33.6198C43.654 34.3189 43.3784 34.9893 42.8882 35.4836C42.398 35.9779 41.7333 36.2556 41.04 36.2556ZM5.72461 9.21236C5.54392 9.20886 5.36414 9.24174 5.19619 9.30905C5.02825 9.37636 4.87528 9.47679 4.74627 9.60442C4.61727 9.73204 4.51499 9.88426 4.44505 10.0523C4.37511 10.2203 4.33907 10.4008 4.33911 10.583V33.5671C4.33907 33.7493 4.37511 33.9297 4.44505 34.0978C4.51499 34.2658 4.61727 34.418 4.74627 34.5457C4.87528 34.6733 5.02825 34.7737 5.19619 34.841C5.36414 34.9083 5.54392 34.9412 5.72461 34.9377H41.04C41.2207 34.9412 41.4005 34.9083 41.5684 34.841C41.7364 34.7737 41.8894 34.6733 42.0184 34.5457C42.1474 34.418 42.2497 34.2658 42.3196 34.0978C42.3895 33.9297 42.4256 33.7493 42.4255 33.5671V10.583C42.4256 10.4008 42.3895 10.2203 42.3196 10.0523C42.2497 9.88426 42.1474 9.73204 42.0184 9.60442C41.8894 9.47679 41.7364 9.37636 41.5684 9.30905C41.4005 9.24174 41.2207 9.20886 41.04 9.21236H5.72461Z"
      fill="#475467"
    />
    <path
      d="M43.8633 41.0015H3.13669C2.30476 41.0015 1.50662 40.6683 0.918353 40.0751C0.330084 39.482 0 38.6775 0 37.8386C0 37.6639 0.0689005 37.4962 0.191456 37.3726C0.314012 37.249 0.480184 37.1797 0.653505 37.1797H46.3465C46.5198 37.1797 46.686 37.249 46.8085 37.3726C46.9311 37.4962 47 37.6639 47 37.8386C47 38.254 46.919 38.6652 46.7613 39.0489C46.6037 39.4327 46.3723 39.7814 46.081 40.0751C45.7897 40.3688 45.4443 40.6018 45.0637 40.7608C44.6832 40.9197 44.2752 41.0015 43.8633 41.0015ZM1.41167 38.4976C1.54691 38.8499 1.78335 39.1534 2.0907 39.3694C2.39806 39.5854 2.7621 39.7039 3.13669 39.7099H43.8633C44.2379 39.7039 44.6019 39.5854 44.9093 39.3694C45.2166 39.1534 45.4531 38.8499 45.5883 38.4976H1.41167Z"
      fill="#475467"
    />
    <path
      d="M16.8604 30.0076H10.7172C10.5484 30.0008 10.3893 29.9284 10.2724 29.8055C10.1554 29.6827 10.0897 29.5189 10.0898 29.3487V21.7839C10.0897 21.6137 10.1554 21.4499 10.2724 21.3271C10.3893 21.2042 10.5484 21.1318 10.7172 21.125H16.8604C17.0337 21.125 17.1999 21.1945 17.3224 21.3181C17.445 21.4416 17.5139 21.6092 17.5139 21.7839V29.375C17.5071 29.5452 17.4353 29.7061 17.3135 29.824C17.1917 29.942 17.0293 30.0077 16.8604 30.0076ZM11.3707 28.6897H16.2069V22.4165H11.3707V28.6897Z"
      fill="#475467"
    />
    <path
      d="M26.5844 30.0087H20.4418C20.2685 30.0087 20.1017 29.9392 19.9791 29.8156C19.8566 29.6921 19.7883 29.5245 19.7883 29.3497V19.3074C19.7883 19.1326 19.8566 18.9651 19.9791 18.8415C20.1017 18.7179 20.2685 18.6484 20.4418 18.6484H26.5844C26.7577 18.6484 26.9239 18.7179 27.0464 18.8415C27.169 18.9651 27.2379 19.1326 27.2379 19.3074V29.3497C27.2379 29.5245 27.169 29.6921 27.0464 29.8156C26.9239 29.9392 26.7577 30.0087 26.5844 30.0087ZM21.0953 28.6908H25.9309V19.9663H21.0953V28.6908Z"
      fill="#475467"
    />
    <path
      d="M36.3346 30.0072H30.1921C30.0188 30.0072 29.8526 29.9378 29.73 29.8142C29.6075 29.6906 29.5386 29.5231 29.5386 29.3483V15.1941C29.5386 15.0193 29.6075 14.8518 29.73 14.7282C29.8526 14.6046 30.0188 14.5352 30.1921 14.5352H36.3346C36.508 14.5352 36.6741 14.6046 36.7967 14.7282C36.9192 14.8518 36.9881 15.0193 36.9881 15.1941V29.3483C36.9881 29.5231 36.9192 29.6906 36.7967 29.8142C36.6741 29.9378 36.508 30.0072 36.3346 30.0072ZM30.8456 28.6893H35.6811V15.8531H30.8456V28.6893Z"
      fill="#475467"
    />
  </svg>
);

export default CareersELearning;
